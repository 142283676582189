import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../AuthConfig';
import { FileUpload } from '../components/formbuilder/models/FileUpload';
import { FormModel } from '../components/formbuilder/models/FormModel';
import { User } from '../models/User';
import { Application } from '../models/Application';
import { ApplicationDetails } from '../models/ApplicationDetails';
import { Organisation } from '../models/Organisation';
import { ApplicationPeriod } from '../models/ApplicationPeriod';
import { ApplicationPeriodDetails } from '../models/ApplicationPeriodDetails';
import { getSettings } from '../Settings';
import { handleError } from '../utils/handleErrors';

class Api {
  private static baseUrl: string = getSettings().SERVER_URL;

  private instance: IPublicClientApplication;

  constructor(instance: IPublicClientApplication) {
    this.instance = instance;
  }

  public async createApplication(
    organisationId: string,
    applicationPeriodId: string,
  ): Promise<ApplicationDetails> {
    const response = await this.post(`/organisations/${organisationId}/applications`, {
      applicationPeriodId: applicationPeriodId,
    });

    return response.json();
  }

  public async updateApplication(
    organisationId: string,
    applicationId: string,
    model: FormModel,
    submit: boolean,
  ): Promise<Response> {
    const response = await this.put(
      `/organisations/${organisationId}/applications/${applicationId}`,
      {
        json: model,
        submit: submit,
      },
    );

    return response;
  }

  public uploadFile = async (
    organisationId: string,
    applicationId: string,
    fileUpload: FileUpload,
  ) => {
    const { elementId } = fileUpload;
    const response = await this.post(
      `/organisations/${organisationId}/applications/${applicationId}/files/${elementId}`,
      fileUpload,
    );

    fileUpload.uploadComplete();

    return response;
  };

  public deleteUploadedFile = async (
    organisationId: string,
    applicationId: string,
    elementId: string,
  ) => {
    const response = await this.delete(
      `/organisations/${organisationId}/applications/${applicationId}/files/${elementId}`,
    );

    return response;
  };

  public async getApplicationPeriods(organisationId: string): Promise<ApplicationPeriod[]> {
    return this.get<ApplicationPeriod[]>(
      `/organisations/${organisationId}/applicationperiods`,
      false,
    );
  }

  public async getApplicationPeriod(
    organisationId: string,
    id: any,
  ): Promise<ApplicationPeriodDetails> {
    return this.get<ApplicationPeriodDetails>(
      `/organisations/${organisationId}/applicationperiods/${id}`,
      true,
    );
  }

  public async getPayments(organisationId: string, applicationId: string): Promise<any[]> {
    return this.get<any[]>(`/organisations/${organisationId}/payments/${applicationId}`, true);
  }

  public async claimPayment(
    organisationId: string,
    applicationId: string,
    claim: any,
  ): Promise<Response> {
    return this.post(`/organisations/${organisationId}/payments/${applicationId}`, claim);
  }

  public async updateUser(user: User): Promise<Response> {
    return this.put('/user', user);
  }

  public async getUser(): Promise<User> {
    return this.get<User>('/user', true);
  }

  public async deleteApplication(organisationId: string, applicationId: string): Promise<Response> {
    return this.delete(`/organisations/${organisationId}/applications/${applicationId}`);
  }

  public async getApplications(organisationId: string): Promise<Application[]> {
    return this.get<Application[]>(`/organisations/${organisationId}/applications`, true);
  }

  public async getApplicationsByApplicationPeriodId(
    organisationId: string,
    applicationPeriodId: string,
  ): Promise<Application[]> {
    return this.get<Application[]>(
      `/organisations/${organisationId}/applications?applicationPeriodId=${applicationPeriodId}`,
      true,
    );
  }

  public async getApplication(
    organisationId: string,
    applicationId: string,
  ): Promise<ApplicationDetails> {
    return this.get<ApplicationDetails>(
      `/organisations/${organisationId}/applications/${applicationId}`,
      true,
    );
  }

  public async getOrganisation(slug: string): Promise<Organisation> {
    return this.get<Organisation>(`/organisations/${slug}`, false);
  }


  public async getImageUrl(
    organisationId: string,
    imgId: string,
    format?: string,
  ): Promise<string> {
    const options = await this.getOptions('GET', true, null, 'octet-stream/octet-stream');

    let url = `${Api.baseUrl}${`/organisations/${organisationId}/downloads/imageUrl/${imgId}`}`;
    if (format) {
      url = `${url}?format=${format}`;
    }

    const response = await fetch(
      url,
      options,
    );
    if (!response.ok) {
      await handleError(response);
    }

    return response.text();
  }


  public async downloadFile(organisationId: string, fileId: string): Promise<ArrayBuffer> {
    const options = await this.getOptions('GET', true, null, 'octet-stream/octet-stream');

    const response = await fetch(
      `${Api.baseUrl}${`/organisations/${organisationId}/downloads/${fileId}`}`,
      options,
    );
    if (!response.ok) {
      await handleError(response);
    }

    return response.arrayBuffer();
  }

  private async get<T>(url: string, useToken: boolean): Promise<T> {
    const options = await this.getOptions('GET', useToken);
    const response = await fetch(`${Api.baseUrl}${url}`, options);
    if (!response.ok) {
      await handleError(response);
    }
    return response.json();
  }

  private async post(url: string, body: any | FileUpload): Promise<Response> {
    let options: RequestInit;

    if (body instanceof FileUpload) {
      options = await this.getFileUploadOptions('POST', true, body);
    } else {
      options = await this.getOptions('POST', true, JSON.stringify(body));
    }

    const response = await fetch(`${Api.baseUrl}${url}`, options);
    if (!response.ok) {
      await handleError(response);
    }

    return response;
  }

  private async put(url: string, body: any): Promise<Response> {
    const options = await this.getOptions('PUT', true, JSON.stringify(body));
    const response = await fetch(`${Api.baseUrl}${url}`, options);
    if (!response.ok) {
      await handleError(response);
    }
    return response;
  }

  private async delete(url: string): Promise<Response> {
    const options = await this.getOptions('DELETE', true);
    const response = await fetch(`${Api.baseUrl}${url}`, options);
    if (!response.ok) {
      await handleError(response);
    }
    return response;
  }

  private getFileUploadOptions = async (
    method: string,
    useToken: boolean,
    body: FileUpload,
  ): Promise<any> => {
    const formData = new FormData();
    const blob = new Blob([body.data], { type: body.type });
    formData.append('file', blob, body.name);

    const options = {
      method,
      headers: {
        Accept: 'application/json',
      },
      body: formData,
    } as any;

    if (useToken) {
      const token = await this.getToken();

      options.headers.Authorization = `Bearer ${token}`;
    }

    return options;
  };

  private async getOptions(
    method: string,
    useToken: boolean,
    body: any | null = null,
    accept = 'application/json',
  ): Promise<RequestInit> {
    if (useToken === true) {
      const token = await this.getToken();

      return {
        method,
        headers: {
          accept,
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body,
      };
    }

    return {
      method,
      headers: {
        accept,
        'Content-Type': 'application/json',
      },
      body,
    };
  }

  private async getToken(): Promise<string> {
    // MSAL.js v2 exposes several account APIs, logic to determine which
    // account to use is the responsibility of the developer
    const account = this.instance.getAllAccounts()[0];
    const accessTokenRequest = {
      ...loginRequest,
      account,
    };

    try {
      const tokenResponse = await this.instance.acquireTokenSilent(accessTokenRequest);
      return tokenResponse.accessToken;
    } catch (error) {
      await this.instance.acquireTokenRedirect(loginRequest);
      throw error;
    }
  }

  public respondToCompletion = async (
    organisationId: string,
    applicationId: string,
    comment: string,
  ): Promise<Response> => {
    return this.post(
      `/organisations/${organisationId}/applications/${applicationId}/completion-comment`,
      {
        comment,
      },
    );
  };
}

export const useApi = (): Api => {
  const { instance } = useMsal();
  return new Api(instance);
};
